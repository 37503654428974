import React from 'react';

import styles from "./composes.module.scss";

export const Composes = () => {

    return <div  >
        <h1> composes</h1>

        <div className={styles.box1}>    1  </div>
        <div className={styles.box2}>    2  </div>
        <div className={styles.box3}>    3  </div>
    </div>
};
 