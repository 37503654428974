


 export const SubItem1 =()=>{

 return <h3>
  sub item1
 </h3>
 }

 export const SubItem2 =()=>{

 return <h3>
  sub item2
 </h3>
 }

 const index= {
  SubItem1,
      SubItem2
 }


 export default index