import React from 'react';

export const Home=(( )=> {

        return  <div>
            <h1>
               Smart ui
            </h1>

        </div>
    }
)