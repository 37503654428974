import React from 'react';
import  styles from './css.module.scss'

export const Css = () => {

    return <div  >
            <h1> css</h1>

        <div className={styles.box1}>    1  </div>
        <div className={styles.box2}>    2  </div>
        <div className={styles.box3}>    3  </div>
    </div>
};
 